import React from 'react';
import Header from './../components/header';
import Footer from './../components/footer';

function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="main-content mt-[110px] mb-[30px]">
                <div className="main-container grid grid-cols-1 flex text-gray-300 mt-1 py-3">
                    <h1 className="text-3xl font-bold mb-4 text-white">
                        Privacy Policy
                    </h1>
                    <p className="py-1 text-sm">
                        This privacy policy has been compiled to better serve
                        those who are concerned with how their 'Personally
                        Identifiable Information' (PII) is being used online.
                        PII, as described in US privacy law and information
                        security, is information that can be used on its own or
                        with other information to identify, contact, or locate a
                        single person, or to identify an individual in context.
                        Please read our privacy policy carefully to get a clear
                        understanding of how we collect, use, protect or
                        otherwise handle your Personally Identifiable
                        Information in accordance with our website.
                    </p>
                    <p className="py-1 text-sm">
                        Thank you for choosing to be part of our community at
                        Resultid Inc., doing business as Resultid (“Resultid”,
                        “we”, “us”, or “our”). We are committed to protecting
                        your personal information and your right to privacy. If
                        you have any questions or concerns about our policy, or
                        our practices with regards to your personal information,
                        please contact us at{' '}
                        <a
                            className="text-blue-600"
                            href="https://www.resultid.ai"
                        >
                            https://www.resultid.ai/contact
                        </a>
                        .
                    </p>
                    <p className="py-1 text-sm">
                        When you visit our website{' '}
                        <a
                            className="text-blue-600"
                            href="https://www.resultid.ai"
                        >
                            https://www.resultid.ai
                        </a>
                        , mobile application, and use our services, you trust us
                        with your personal information. We take your privacy
                        very seriously. In this privacy notice, we describe our
                        privacy policy. We seek to explain to you in the
                        clearest way possible what information we collect, how
                        we use it and what rights you have in relation to it. We
                        hope you take some time to read through it carefully, as
                        it is important. If there are any terms in this privacy
                        policy that you do not agree with, please discontinue
                        use of our Sites and our services.
                    </p>
                    <p className="py-1 text-sm">
                        This privacy policy applies to all information collected
                        through our website (such as{' '}
                        <a
                            className="text-blue-600"
                            href="https://www.resultid.ai"
                        >
                            https://www.resultid.ai
                        </a>{' '}
                        and/or any related services, sales, marketing or events
                        (we refer to them collectively in this privacy policy as
                        the "Sites"). Please read this privacy policy carefully
                        as it will help you make informed decisions about
                        sharing your personal information with us.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        What personal information do we collect from the people
                        that visit our blog, website or app?
                    </h2>
                    <p className="py-1 text-sm">
                        When ordering or registering on our site, as
                        appropriate, you may be asked to enter your name, email
                        address, mailing address, phone number or other details
                        to help you with your experience.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        What information do we collect? <br /> In Short: We
                        collect personal information that you provide to us such
                        as name, address, contact information, passwords and
                        security data, and payment information.
                    </h2>
                    <p className="py-1 text-sm">
                        We collect personal information that you voluntarily
                        provide to us when registering at the Sites expressing
                        an interest in obtaining information about us or our
                        products and services, when participating in activities
                        on the Sites (such as posting messages in our online
                        forums or entering competitions, contests or giveaways)
                        or otherwise contacting us.
                    </p>
                    <p className="py-1 text-sm">
                        The personal information that we collect depends on the
                        context of your interactions with us and the Sites, the
                        choices you make and the products and features you use.
                        The personal information we collect can include the
                        following:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            Name and Contact Data. We collect your first and
                            last name, email address, postal address, phone
                            number, and other similar contact data.
                        </li>
                        <li>
                            Credentials. We collect passwords, password hints,
                            and similar security information used for
                            authentication and account access.
                        </li>
                        <li>
                            Payment Data. We collect data necessary to process
                            your payment if you make purchases, such as your
                            payment instrument number (such as a credit card
                            number), and the security code associated with your
                            payment instrument. All payment data is stored by
                            our payment processor and you should review its
                            privacy policies and contact the payment processor
                            directly to respond to your questions.
                        </li>
                        <li>
                            All personal information that you provide to us must
                            be true, complete and accurate, and you must notify
                            us of any changes to such personal information.
                        </li>
                    </ul>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Information automatically collected <br /> In Short:
                        Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is
                        collected automatically when you visit our Sites.
                    </h2>
                    <p className="py-1 text-sm">
                        We automatically collect certain information when you
                        visit, use or navigate the Sites. This information does
                        not reveal your specific identity (like your name or
                        contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Sites and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Sites, and for our internal analytics and
                        reporting purposes.
                    </p>
                    <p className="py-1 text-sm">
                        Like many businesses, we also collect information
                        through cookies and similar technologies.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Information collected from other sources
                    </h2>
                    <p className="py-1 text-sm">
                        In Short: We may collect limited data from public
                        databases, marketing partners, and other outside
                        sources. We may obtain information about you from other
                        sources, such as public databases, joint marketing
                        partners, as well as from other third parties. Examples
                        of the information we receive from other sources include
                        social media profile information; marketing leads and
                        search results and links, including paid listings (such
                        as sponsored links).
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        How do we use your information? <br /> In Short: We
                        process your information for purposes based on
                        legitimate business interests, the fulfillment of our
                        contract with you, compliance with our legal
                        obligations, and/or your consent.
                    </h2>
                    <p className="py-1 text-sm">
                        We use personal information collected via our Sites for
                        a variety of business purposes described below. We
                        process your personal information for these purposes in
                        reliance on our legitimate business interests ("Business
                        Purposes"), in order to enter into or perform a contract
                        with you ("Contractual"), with your consent ("Consent"),
                        and/or for compliance with our legal obligations ("Legal
                        Reasons"). We indicate the specific processing grounds
                        we rely on next to each purpose listed below.
                    </p>
                    <p className="py-1 text-sm">
                        We use the information we collect or receive:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            To facilitate account creation and logon process. If
                            you choose to link your account with us to a
                            third-party account *(such as your Google or
                            Facebook account), we use the information you
                            allowed us to collect from those third parties to
                            facilitate account creation and logon process.
                        </li>
                        <li>
                            To send you marketing and promotional
                            communications. We and/or our third-party marketing
                            partners may use the personal information you send
                            to us for our marketing purposes, if this is in
                            accordance with your marketing preferences. You can
                            opt out of our marketing emails at any time (see the
                            " WHAT ARE YOUR PRIVACY RIGHTS " below).
                        </li>
                        <li>
                            To send administrative information to you. We may
                            use your personal information to send you product,
                            service and new feature information and/or
                            information about changes to our terms, conditions,
                            and policies.
                        </li>
                        <li>
                            Fulfilll and manage your orders. We may use your
                            information to fulfill and manage your orders,
                            payments, returns, and exchanges made through the
                            Sites.
                        </li>
                        <li>
                            To post testimonials. We post testimonials on our
                            Sites that may contain personal information. Prior
                            to posting a testimonial, we will obtain your
                            consent to use your name and testimonial. If you
                            wish to update, or delete your testimonial, please
                            contact us at{' '}
                            <a
                                className="text-blue-600"
                                href="mailto:info@resultid.com"
                            >
                                info@resultid.com
                            </a>
                            and be sure to include your name, testimonial
                            location, and contact information.
                        </li>
                        <li>
                            Request Feedback. We may use your information to
                            request feedback and to contact you about your use
                            of our Sites.
                        </li>
                        <li>
                            To protect our Sites. We may use your information as
                            part of our efforts to keep our Sites safe and
                            secure (for example, for fraud monitoring and
                            prevention).
                        </li>
                        <li>
                            To enable user to user communications. We may use
                            your information in order to enable user to user
                            communications with each user's consent.
                        </li>
                        <li>To enforce our terms, conditions and policies.</li>
                        <li>
                            To respond to legal requests and prevent harm. If we
                            receive a subpoena or other legal request, we may
                            need to inspect the data we hold to determine how to
                            respond.
                        </li>
                        <li>
                            For other Business Purposes. We may use your
                            information for other Business Purposes, such as
                            data analysis, identifying usage trends, determining
                            the effectiveness of our promotional campaigns and
                            to evaluate and improve our Sites, products,
                            services, marketing and your experience.
                        </li>
                    </ul>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Will your information be shared with anyone? <br /> In
                        Short: We only share information with your consent, to
                        comply with laws, to protect your rights, or to fulfill
                        business obligations.
                    </h2>
                    <p className="py-1 text-sm">
                        We may process or share data based on the following
                        legal basis:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            Consent: We may process your data if you have given
                            us specific consent to use your personal information
                            in a specific purpose.
                        </li>
                        <li>
                            Legitimate Interests: We may process your data when
                            it is reasonably necessary to achieve our legitimate
                            business interests.
                        </li>
                        <li>
                            Performance of a Contract: Where we have entered
                            into a contract with you, we may process your
                            personal information to fulfill the terms of our
                            contract.
                        </li>
                        <li>
                            Legal Obligations: We may disclose your information
                            where we are legally required to do so in order to
                            comply with applicable law, governmental requests, a
                            judicial proceeding, court order, or legal process,
                            such as in response to a court order or a subpoena
                            (including in response to public authorities to meet
                            national security or law enforcement requirements).
                        </li>
                        <li>
                            Vital Interests: We may disclose your information
                            where we believe it is necessary to investigate,
                            prevent, or take action regarding potential
                            violations of our policies, suspected fraud,
                            situations involving potential threats to the safety
                            of any person and illegal activities, or as evidence
                            in litigation in which we are involved.
                        </li>
                    </ul>
                    <p className="py-1 text-sm">
                        More specifically we may need to process your data or
                        share your personal information in the following
                        situations:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            Business Transfers. We may share or transfer your
                            information in connection with, or during
                            negotiations of, any merger, sale of company assets,
                            financing,or acquisition of all or a portion of our
                            business to another company.
                        </li>
                        <li>
                            Third Party Advertisers. We may use third party
                            advertising companies to serve ads when you visit
                            the Sites. These companies may use information about
                            your visits to our Website(s) and other websites
                            that are contained in web cookies and other tracking
                            technologies in order to provide advertisements
                            about goods and services of interest to you.
                        </li>
                    </ul>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        How long do we keep your information? <br /> In Short:
                        We keep your information for as long as necessary to
                        fulfill the purposes outlined in this privacy notice
                        unless otherwise required by law.
                    </h2>
                    <p className="py-1 text-sm">
                        We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to access or store
                        information. Specific information about how we use such
                        technologies and how you can refuse certain cookies is
                        set out in our Cookie Policy.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        How do we keep your information safe? <br /> In Short:
                        We aim to protect your personal information through a
                        system of organizational and technical security
                        measures.
                    </h2>
                    <p className="py-1 text-sm">
                        We have implemented appropriate technical and
                        organizational security measures designed to protect the
                        security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security, and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Sites is at your
                        own risk. You should only access the services within a
                        secure environment.
                    </p>
                    <p className="py-1 text-sm">
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy policy, unless a longer retention period is
                        required or permitted by law (such as tax, accounting,
                        or other legal requirements). No purpose in this policy
                        will require us keeping your personal information for
                        longer than 90 days past the termination of the user’s
                        account.
                    </p>
                    <p className="py-1 text-sm">
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize it, or, if this is not possible (for
                        example, because your personal information has been
                        stored in backup archives), then we will securely store
                        your personal information and isolate it from any
                        further processing until deletion is possible.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        How do we keep your information safe?
                        <br />
                        In Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures.
                    </h2>
                    <p className="py-1 text-sm">
                        We have implemented appropriate technical and
                        organizational security measures designed to protect the
                        security of any personal information we process.
                        However, please also remember that we cannot guarantee
                        that the internet itself is 100% secure. Although we
                        will do our best to protect your personal information,
                        transmission of personal information to and from our
                        Sites is at your own risk. You should only access the
                        services within a secure environment.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Do we collect information from minors?
                        <br />
                        In Short: We do not knowingly collect data from or
                        market to children under 18 years of age.
                    </h2>
                    <p className="py-1 text-sm">
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Sites, you
                        represent that you are at least 18 or that you are the
                        parent or guardian of such a minor and consent to such
                        minor dependent’s use of the Sites. If we learn that
                        personal information from users less than 18 years of
                        age has been collected, we will deactivate the account
                        and take reasonable measures to promptly delete such
                        data from our records. If you become aware of any data
                        we have collected from children under age 18, please
                        contact us at info@resultiud.com
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        What are your privacy rights?
                        <br />
                        In Short: You may review, change, or terminate your
                        account at any time.
                    </h2>
                    <p className="py-1 text-sm">
                        If you are resident in the European Economic Area and
                        you believe we are unlawfully processing your personal
                        information, you also have the right to complain to your
                        local data protection supervisory authority. You can
                        find their contact details here:
                        http://ec.europa.eu/justice/data
                        protection/bodies/authorities/index_en.htm
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Account Information
                    </h2>
                    <p className="py-1 text-sm">
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            Log into your account settings and update your user
                            account. Contact us using the contact information
                            provided. Upon your request to terminate your
                            account, we will deactivate or delete your account
                            and information from our active databases. However,
                            some information may be retained in our iles to
                            prevent fraud, troubleshoot problems, assist with
                            any investigations, enforce our Terms of Use and/or
                            comply with legal requirements.
                        </li>
                        <li>
                            Cookies and similar technologies: Most Web browsers
                            are set to accept cookies by default. If you prefer,
                            you can usually choose to set your browser to remove
                            cookies and to reject cookies. If you choose to
                            remove cookies or reject cookies, this could affect
                            certain features or services of our Sites. To opt
                            out of interest based advertising by advertisers on
                            our Sites visit http://www.aboutads.info/choices/.
                        </li>
                        <li>
                            Opting out of email marketing: You can unsubscribe
                            from our marketing email list at any time by
                            clicking on the unsubscribe link in the emails that
                            we send or by contacting us using the details
                            provided below. You will then be removed from the
                            marketing email list – however, we will still need
                            to send you service-related emails that are
                            necessary for the administration and use of your
                            account. To otherwise opt- out, you may contact us
                            using the contact information provided. Access your
                            account settings and update preferences
                        </li>
                    </ul>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Controls for do-not-track features
                    </h2>
                    <p className="py-1 text-sm">
                        Most web browsers and some mobile operating systems and
                        mobile applications include a DoNotTrack (“DNT”) feature
                        or setting you can activate to signal your privacy
                        preference not to have data about your online browsing
                        activities monitored and collected. No uniform
                        technology standard for recognizing and implementing DNT
                        signals has been finalized. As such, we do not currently
                        respond to DNT browser signals or any other mechanism
                        that automatically communicates your choice not to be
                        tracked online. If a standard for online tracking is
                        adopted that we must follow in the future, we will
                        inform you about that practice in a revised version of
                        this Privacy Policy.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Do California residents have privacy rights?
                        <br />
                        In Short: Yes, if you are a resident of California, you
                        are granted speciic rights regarding access to your
                        personal information.
                    </h2>
                    <p className="py-1 text-sm">
                        California Civil Code Section 1798.83, also known as the
                        “Shine The Light” law, permits our users who are
                        California residents to request and obtain from us, once
                        a year and free of charge, information about categories
                        of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared
                        personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.
                    </p>
                    <p className="py-1 text-sm">
                        If you are under 18 years of age, reside in California,
                        and have a registered account with the Sites, you have
                        the right to request removal of unwanted data that you
                        publicly post on the Sites. To request removal of such
                        data, please contact us using the contact information
                        provided below, and include the email address associated
                        with your account and a statement that you reside in
                        California. We will make sure the data is not publicly
                        displayed on the Sites, but please be aware that the
                        data may not be completely or comprehensively removed
                        from our systems.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Do we make updates to this policy?
                        <br />
                        In Short: Yes, we will update this policy as necessary
                        to stay compliant with relevant laws.
                    </h2>
                    <p className="py-1 text-sm">
                        We may update this privacy policy from time to time. The
                        updated version will be indicated by an updated
                        “Revised” date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy policy, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy policy frequently to be informed of
                        how we are protecting your information.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        How can you contact us about this policy?
                    </h2>
                    <p className="py-1 text-sm">
                        If you have questions or comments about this policy, you
                        may email us at{' '}
                        <a
                            className="text-blue-600"
                            href="mailto:info@resultid.com"
                        >
                            info@resultid.com
                        </a>
                        .
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
