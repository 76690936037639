import React from 'react';
import Header from './../components/header';
import PricingContent from './content/pricingContent';
import Footer from './../components/footer';

function Pricing() {
    return (
        <div>
            <Header />
            <PricingContent />
            <Footer />
        </div>
    );
}
export default Pricing;
