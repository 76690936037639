import React from 'react';
import Header from './../components/header';
import Footer from './../components/footer';
import BlogPosts from './content/BlogPosts';
import './../assets/css/styles.css';

function Blog() {
    return (
        <div>
            <Header />
            <div className="main-content">
                <section className="section-pricing">
                    <div className="section-bg section-bg-pricing">
                        <div className="bg-pricing-image">
                            <img
                                src="/assets/images/bg-pricing.jpg"
                                alt="Resultid"
                            />
                        </div>
                    </div>
                    <div className="main-container grid grid-cols-1 flex text-gray-300 mt-1 py-3">
                        <BlogPosts />
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
export default Blog;
