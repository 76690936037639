import React, { useEffect } from 'react';
import './../assets/css/styles.css';
import './../assets/css/verticalRotator.css';

import HubspotForm from 'react-hubspot-form';
import Accordian from './accordian';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function Main() {
    useEffect(() => {
        const verticalRotatorElement = document.querySelector('.mask');

        if (verticalRotatorElement) {
            import('./../assets/js/verticalRotator').then(
                ({ default: verticalRotator }) => {
                    verticalRotator();
                }
            );
        }

        const panelItems = document.querySelectorAll(
            '.home-hero-image-panel-item'
        );

        panelItems.forEach((panelItem) => {
            panelItem.addEventListener('animationend', function () {
                panelItem.classList.add('animation-stopped');
            });
        });
    }, []);

    return (
        <div className="main-content">
            <section className="section-home-hero">
                <div className="main-container">
                    <div className="home-hero-wrapper flex items-center">
                        <div className="home-hero-text">
                            <h1>
                                AI Insights for
                                {
                                    <div className="mask">
                                        <span
                                            className="w-full text-center md:text-left"
                                            data-show
                                        >
                                            Smarter
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Better
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Faster
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            More Informed
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Data-Driven
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Strategic
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Actionable
                                        </span>
                                        <span className="w-full text-center md:text-left">
                                            Optimized
                                        </span>
                                    </div>
                                }{' '}
                                Decisions.
                            </h1>
                            <div className="mt-[60px] text-white max-w-[500px]">
                                <div className="font-bold text-lg mb-[8px]">
                                    Securely connect your{' '}
                                    <span className="text-gradient-colorful">
                                        unstructured data to ChatGPT
                                    </span>
                                    .
                                </div>
                                <div className="text-base leading-7">
                                    Gain real-time insights, predictive
                                    analytics, and risk reduction. Make smarter
                                    decisions without the need for coding or
                                    extensive data expertise.
                                </div>
                            </div>
                            <div className="home-hero-text-form flex flex-col">
                                <h2 className="text-center text-2xl font-bold">
                                    Trusted by Leading Enterprises
                                </h2>
                                <div className="flex justify-start space-x-8 mt-6 items-center overflow-x-scroll md:overflow-hidden">
                                    <a href="https://www.bmwgroup.com/en.html">
                                        <img
                                            src="https://content.influencemap.org//site/data/000/937/937168.png"
                                            alt="BMW Group"
                                            className="h-[70px]"
                                            style={{
                                                filter: 'grayscale(100%) brightness(0%) invert(100%)',
                                            }}
                                        />
                                    </a>
                                    <a href="https://www.dorianlpg.com/home/default.aspx">
                                        <img
                                            src="https://logo.stocklight.com/NYSE/LPG.png"
                                            alt="Dorian LPG"
                                            className="h-[60px]"
                                            style={{
                                                filter: 'grayscale(100%) brightness(0%) invert(100%)',
                                            }}
                                        />
                                    </a>
                                    <a href="https://applegate.com/">
                                        <img
                                            src="https://applegate.com/assets/applegate-logo-w-tag-c8b83861d0a5e532d5074982f68538cc20a04bd06a84e0e6ffb4e348ffe9138a.svg"
                                            alt="Hormel Foods"
                                            className="h-[80px]"
                                            style={{
                                                filter: 'grayscale(100%) brightness(0%) invert(100%)',
                                            }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="home-hero-image">
                            <img
                                src="./assets/images/hero-image-new.png"
                                alt="Resultid"
                            />
                            <div className="home-hero-image-panel">
                                <div className="home-hero-image-panel-item">
                                    <img
                                        src="./assets/icons/csv.svg"
                                        alt="Resultid"
                                    />
                                    <p>Surveys, feedback, other form data</p>
                                </div>
                                <div className="home-hero-image-panel-item">
                                    <img
                                        src="./assets/icons/pdf.svg"
                                        alt="Resultid"
                                    />
                                    <p>Research reports &amp; presentations</p>
                                </div>
                                <div className="home-hero-image-panel-item">
                                    <img
                                        src="./assets/icons/words.svg"
                                        alt="Resultid"
                                    />
                                    <p>Call transcripts, messages, emails</p>
                                </div>
                                <div className="home-hero-image-panel-item">
                                    <img
                                        src="./assets/icons/slides.svg"
                                        alt="Resultid"
                                    />
                                    <p>Any other text data!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-read-more justify-center items-start w-full h-20 z-10 hidden md:flex">
                <a
                    href="#home-power"
                    className="flex read-more-button text-white uppercase flex-col text-center float hover:shadow-md"
                >
                    <div className="relative">
                        <div className="font-extrabold text-black bg-white rounded px-6 py-2 z-20 text-sm">
                            Read More
                        </div>
                        <div className="mt-2 relative z-1">
                            <div className="absolute inset-x-0 bottom-0 mb-0 flex justify-center">
                                <div
                                    className="w-8 h-8 rounded-md transform rotate-45"
                                    style={{
                                        background:
                                            'linear-gradient(315deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 35%)',
                                    }}
                                ></div>
                            </div>
                            <div className="absolute inset-x-0 bottom-0 flex justify-center text-black">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </div>
                        </div>
                    </div>
                </a>
            </section>
            <section id="home-power" className="pt-6">
                <div className="main-container space-y-10 pt-6">
                    <div className="section-title text-center">
                        <h2>
                            Power of a{' '}
                            <span className="text-gradient-colorful">
                                10x data science team
                            </span>{' '}
                            in your browser
                        </h2>
                    </div>
                    <p className="text-lg text-white text-center">
                        “No code” is a no-go if you need an expert data
                        scientist to run your data.
                        <br />
                        <strong>
                            We’ve felt that pain so we built better
                        </strong>{' '}
                        to give you the ability to tell a good story.
                    </p>
                    <Accordian></Accordian>
                </div>
            </section>
            <section className="section-home-percentage">
                <div className="main-container">
                    <div className="home-percentage-wrapper">
                        <div className="home-percentage-left flex flex-col items-center">
                            <div className="home-percentage-left-bg">
                                <img
                                    src="./assets/images/bg-percentage.png"
                                    alt="Resultid"
                                />
                            </div>
                            <h2>73%</h2>
                            <div className="home-percentage-sadface">
                                <div className="home-percentage-sadface-filled">
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                    <div className="sadface-filled">
                                        <img
                                            src="./assets/icons/sadface.svg"
                                            alt="Resultid"
                                        />
                                    </div>
                                </div>
                                <div className="home-percentage-sadface-empty">
                                    <div className="sadface-empty"> </div>
                                    <div className="sadface-empty"> </div>
                                    <div className="sadface-empty"> </div>
                                </div>
                            </div>
                            <p>
                                Over 70% of corporations do not review their
                                data for insights or trends.{' '}
                                <strong>Why not?</strong>
                            </p>
                            <a
                                className="border-2 border-blue-400 rounded px-3 py-2 text-blue-400 cursor-pointer hover:bg-blue-400 hover:text-white mt-[30px]"
                                href="https://app.resultid.com"
                            >
                                Try for Free!
                            </a>
                        </div>
                        <div className="home-percentage-right">
                            <h2 className="flex flex-col">
                                <span>Don't miss out on</span>
                                <span className="text-gradient-colorful">
                                    tremendous value
                                </span>
                                <span>in untapped data.</span>
                            </h2>
                            <p>
                                The best decisions are made with context.
                                Valuable context is often locked within
                                unstructured text data such as Verbatims, PDFs,
                                Docs, PowerPoints, etc:
                            </p>
                            <div className="cross-list">
                                <div className="cross-list-item">
                                    <img
                                        src="./assets/icons/cross-pink.svg"
                                        alt="Resultid"
                                    />
                                    <p>High cost to analyze</p>
                                </div>
                                <div className="cross-list-item">
                                    <img
                                        src="./assets/icons/cross-pink.svg"
                                        alt="Resultid"
                                    />
                                    <p>
                                        Missed opportunities for data
                                        interpretation
                                    </p>
                                </div>
                                <div className="cross-list-item">
                                    <img
                                        src="./assets/icons/cross-pink.svg"
                                        alt="Resultid"
                                    />
                                    <p>
                                        Difficulty turning qualitative data into
                                        action
                                    </p>
                                </div>
                                <div className="cross-list-item">
                                    <img
                                        src="./assets/icons/cross-pink.svg"
                                        alt="Resultid"
                                    />
                                    <p>
                                        Lack of coherent narrative for
                                        decision-making
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-home-data">
                <div className="section-bg section-bg-data">
                    <div className="bg-data-image">
                        <img src="./assets/images/bg-data.jpg" alt="Resultid" />
                        <img
                            src="./assets/images/bg-data-mobile.jpg"
                            alt="Resultid"
                        />
                    </div>
                </div>
                <div className="main-container">
                    <div className="home-data-wrapper">
                        <div className="home-data-text">
                            <h2>
                                Telling a{' '}
                                <span className="text-gradient-colorful">
                                    compelling story
                                </span>{' '}
                                with data
                            </h2>
                            <p>
                                <strong>Data storytelling</strong> lets you tell
                                a compelling narrative based on the complex
                                analysis that Resultid does automatically for
                                you
                            </p>
                            <p>
                                <strong>Data storytellers</strong> inform and
                                influence others with clarity, alignment, and
                                action
                            </p>
                            <p>
                                <strong>
                                    You can be a great data storyteller without
                                    being a data scientist!
                                </strong>
                            </p>
                        </div>
                        <div className="home-data-image">
                            <div className="home-data-image-blue">
                                <h3>Data Storytelling</h3>
                                <p>
                                    <strong>Effectively tells a story</strong>{' '}
                                    to get buy-in and empowers teams to take
                                    action
                                </p>
                                <img
                                    src="./assets/icons/data-arrow.svg"
                                    alt="Resultid"
                                />
                            </div>
                            <div className="home-data-image-pink">
                                <h3>Data Science</h3>
                                <p>
                                    Pretty graphs don't tell the true story -{' '}
                                    <strong>
                                        interpretation lacks clarity
                                    </strong>{' '}
                                    and direction
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-home-story">
                <div className="section-bg section-bg-story">
                    <div className="bg-story-image">
                        <img
                            src="./assets/images/bg-story.jpg"
                            alt="Resultid"
                        />
                    </div>
                </div>
                <div className="main-container">
                    <div className="section-title text-center">
                        <h2>
                            <span className="text-gradient-colorful">
                                Become a hero
                            </span>{' '}
                            with a great story
                        </h2>
                        <p>
                            <strong>Which journey will you embark on?</strong>{' '}
                            Select a story below to learn how storytellers made
                            an impact in their organizations
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 my-8">
                        <div className="text-white p-[40px] text-center flex flex-col items-center border-2 justify-between border-blue-500 rounded">
                            <div>
                                <h3 className="text-xl font-bold mb-4">
                                    Customer Success
                                </h3>
                                <p className="text-sm mb-4">
                                    <strong>
                                        Improve the customer experience
                                    </strong>
                                </p>
                                <p className="text-sm mb-8">
                                    Automatically summarize feedback from
                                    surveys, reviews, and social media.{' '}
                                </p>
                            </div>
                            <a
                                className="text-blue-500 font-semibold hover:text-blue-400 mb-0 pb-0"
                                href="https://app.resultid.com"
                            >
                                Join for happier customers
                            </a>
                        </div>
                        <div className="text-white p-[40px] text-center flex flex-col items-center border-2 justify-between border-violet-500 rounded">
                            <div>
                                <h3 className="text-xl font-bold mb-4">
                                    Product Feedback
                                </h3>
                                <p className="text-sm mb-4">
                                    <strong>
                                        Identify the strengths and weaknesses
                                    </strong>
                                </p>
                                <p className="text-sm mb-8">
                                    Discover how to build a better product using
                                    customers feedback
                                </p>
                            </div>
                            <a
                                className="text-violet-500 font-semibold hover:text-violet-400 mb-0 pb-0"
                                href="https://app.resultid.com"
                            >
                                Join for competitive edge
                            </a>
                        </div>
                        <div className="text-white p-[40px] text-center flex flex-col items-center border-2 justify-between border-pink-500 rounded">
                            <div>
                                <h3 className="text-xl font-bold mb-4">
                                    UX Research
                                </h3>
                                <p className="text-sm mb-4">
                                    <strong>
                                        See a clear picture of your ideal
                                        customers amd buyer personas
                                    </strong>
                                </p>
                                <p className="text-sm mb-8">
                                    Uncover their pain points, motivations, and
                                    decision-making process
                                </p>
                            </div>
                            <a
                                className="text-pink-500 font-semibold hover:text-pink-400 mb-0 pb-0"
                                href="https://app.resultid.com"
                            >
                                Join for better direction
                            </a>
                        </div>
                        <div className="text-white p-[40px] text-center flex flex-col items-center border-2 justify-between border-white rounded">
                            <div>
                                <h3 className="text-xl font-bold mb-4">
                                    Online Reviews
                                </h3>
                                <p className="text-sm mb-4">
                                    <strong>
                                        Quickly synthesize what customers are
                                        saying{' '}
                                    </strong>
                                </p>
                                <p className="text-sm mb-8">
                                    Summarize the key themes that customers have
                                    about your product or service
                                </p>
                            </div>
                            <a
                                className="text-white font-semibold hover:text-white/80 mb-0 pb-0"
                                href="https://app.resultid.com"
                            >
                                Join for a clearer picture
                            </a>
                        </div>
                    </div>
                    <div className="home-story-footer flex flex-col items-center">
                        <p>
                            These are just a few of the many narratives waiting
                            for you. Sign up to see more!
                        </p>
                        <a
                            className="border-2 border-blue-400 rounded px-4 py-2 text-blue-400 cursor-pointer hover:bg-blue-400 hover:text-white mt-[30px]"
                            href="https://app.resultid.com"
                        >
                            Try for Free!
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Main;
