import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import jQuery from 'jquery';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Product from './pages/Product';
import PrivacyPolicy from './pages/PrivacyPolicy';

import scripts from './assets/js/scripts';
import index from './assets/js/index';
import './assets/css/App.css';
import './assets/css/styles.css';
import TermsOfUse from './pages/TermsOfUse';
import Blog from './pages/Blog';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/product/:type" element={<Product />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<Blog />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfUse />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
