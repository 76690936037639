import React, { useState } from 'react';

import './../../assets/css/styles.css';
import NarrativeForm from '../../components/narrativeForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function ProductManager() {
    return (
        <div className="main-content">
            <section className="section-pricing">
                <div className="section-bg section-bg-pricing">
                    <div className="bg-pricing-image">
                        <img
                            src="./../assets/images/bg-pricing.jpg"
                            alt="Resultid"
                        />
                    </div>
                </div>
                <div className="main-container">
                    <div className="section-title pb-3">
                        <h2>
                            Turn Customer Feedback into{' '}
                            <span className="text-gradient-colorful">
                                Product Success
                            </span>
                        </h2>
                    </div>
                </div>
                <div className="main-container text-white w-full text-lg">
                    <p className="py-3 text-gray-300">
                        <strong className="text-white">
                            Disrupt your data analysis game.
                        </strong>{' '}
                        Elevate your data analysis abilities and effectively
                        convert your customer feedback into valuable insights.
                        Our advanced AI/NLP platform deciphers your customer
                        data, enabling you to make informed decisions that
                        prioritize the customer and drive success.
                    </p>
                    <NarrativeForm
                        title="Generate User Personas"
                        description="Generate user personas to understand your ideal customer and build out buyer personas and user segments."
                        prompt="customer_voice"
                        formInputs={{
                            cust_voic_input_1: {
                                value: '',
                                type: 'text',
                                label: 'What is the name of your product or company?',
                                placeholder: `Crabtree & Evelyn Gardener's Hand Therapy Lotion`,
                            },
                            cust_voic_input_2: {
                                value: '',
                                type: 'textarea',
                                label: 'Please describe the product or service.',
                                placeholder:
                                    'Our hand therapy lotion contains shea butter as well as extracts of yarrow, clover and calendula to help soothe and condition work-roughened hands.',
                            },
                            cust_voic_input_3: {
                                value: '',
                                type: 'text',
                                label: 'Please give some context to the data set.',
                                placeholder: 'Amazon reviews',
                            },
                            cust_voic_input_4: {
                                value: '',
                                type: 'textarea',
                                label: 'Please explain why you want to know what customers are saying.',
                                placeholder:
                                    'I want to know how to improve my product.',
                            },
                        }}
                        sampleData={{
                            name: 'Review Data',
                            link: 'https://hubs.ly/Q01BKkk90',
                            file: 'reviewdata.csv',
                        }}
                        tutorialData={{
                            name: process.env
                                .REACT_APP_TUTORIAL_DATA_PRODUCT_FILENAME,
                            s3Key: process.env
                                .REACT_APP_TUTORIAL_DATA_PRODUCT_S3_KEY,
                        }}
                    />
                    <div className="grid flex grid-cols-1 md:grid-cols-2 my-[50px] bg-black/40 px-5 py-[50px] rounded">
                        <div className="p-6 rounded">
                            <h1 className="text-3xl font-bold pb-2">
                                Benefits
                            </h1>
                            <ul className="gap-2 space-y-3 text-gray-300">
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Develop products your customers will
                                        love
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Identify and fix pain points in your
                                        user experience
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Eliminate the noise of too much data
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="p-6 bg-black/40 rounded-xl border-2 border-pink-600">
                            <h1 className="text-3xl font-bold pb-2">
                                Features
                            </h1>
                            <ul className="list-none gap-2 space-y-3 text-gray-300">
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-lg mr-2 mt-[7px] text-teal-400"
                                    />
                                    <span>
                                        Discover your customer’s goals and
                                        generate a user persona
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-lg mr-2 mt-[7px] text-teal-400"
                                    />
                                    <span>
                                        Identify the behaviors, personalities
                                        and frustrations of your typical user
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-lg mr-2 mt-[7px] text-teal-400"
                                    />
                                    <span>
                                        Automatically synthesize your data with
                                        full control to dig deeper
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ProductManager;
