import React from 'react';
import './../../assets/css/styles.css';
import Accordian from '../../components/accordian';
import Faqs from '../../components/faqs';

function pricingContent() {
    return (
        <div className="main-content">
            <section className="section-pricing">
                <div className="section-bg section-bg-pricing">
                    <div className="bg-pricing-image">
                        <img
                            src="./assets/images/bg-pricing.jpg"
                            alt="Resultid"
                        />
                    </div>
                </div>
                <div className="main-container">
                    <div className="section-title text-center">
                        <h2>
                            Say goodbye to{' '}
                            <span className="text-gradient-colorful">
                                hours of data analysis!
                            </span>
                        </h2>
                        <p>
                            <strong>Disrupt your data analysis game.</strong>{' '}
                            Revolutionize the ways you
                            <br />
                            work with data and understand massive amounts of
                            information
                        </p>
                    </div>
                    <div className="pricing-content">
                        <div className="pricing-period hide">
                            <p className="pricing-period-monthly">
                                Billed Monthly
                            </p>
                            <div className="pricing-period-toggle"></div>
                            <p className="pricing-period-annualy">
                                Billed Annually
                            </p>
                        </div>
                        <div className="pricing-grid">
                            <div className="pricing-card blue">
                                <div className="pricing-image blue">
                                    <img
                                        src="./assets/icons/solar-system.svg"
                                        alt="Resultid"
                                    />
                                </div>
                                <h3 className='font-light'>The <b className='font-extrabold'>Starter</b></h3>
                                <p>
                                    Delivering insights from text data and
                                    unlock hidden defaultValue
                                </p>
                                <h2 className="custom">
                                    Get started with our Free version
                                    <br />
                                    to see your data brought to life.
                                </h2>
                                <a
                                    className="btn btn-full btn-blue btn-with-icon btn-pricing"
                                    href="http://app.resultid.com/?redirect=settings%2Fpayment"
                                >
                                    <span>Get Started Now</span>
                                    <img
                                        src="./assets/icons/arrow-right-white.svg"
                                        alt="Resultid"
                                    />
                                </a>
                                <div
                                    className="pricing-feature"
                                    style={{
                                        marginTop: '53px',
                                    }}
                                >
                                    <h4>Included Features</h4>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>5,000</b> tokens
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            Up to <b>5MB</b> of storage across <b>3</b> Documents
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            Access to <b>ChatGPT</b> in Workflows
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-card violet">
                                <div className="pricing-image violet">
                                    <img
                                        src="./assets/icons/rocket.svg"
                                        alt="Resultid"
                                    />
                                </div>
                                <h3 className='font-light'>The <b className='font-extrabold'>Viewer</b></h3>
                                <p>
                                    Easily understand what people are saying in
                                    surveys, reviews, and customer feedback
                                </p>
                                <div className="hide">
                                    <h2 className="monthly">
                                        $<span>65</span>/yr
                                    </h2>
                                    <h2 className="annualy hide">
                                        $<span>60</span>/yr
                                    </h2>
                                    <div className="pricing-slider">
                                        <div className="pricing-slider-limit">
                                            <p>20K</p>
                                            <p>100K</p>
                                        </div>
                                        <div className="pricing-slider-wrapper">
                                            <input
                                                type="range"
                                                defaultValue="50000"
                                                step="10000"
                                                min="20000"
                                                max="100000"
                                            />
                                        </div>
                                    </div>
                                    <p className="white">
                                        <span className="bold">50000</span>{' '}
                                        Token/yr
                                    </p>
                                </div>
                                <h2 className="monthly">
                                    $<span>49</span>/mo
                                </h2>
                                <a
                                    className="btn btn-full btn-violet btn-with-icon btn-pricing"
                                    href="http://app.resultid.com/?redirect=settings%2Fpayment"
                                >
                                    <span>Subscribe</span>
                                    <img
                                        src="./assets/icons/arrow-right-white.svg"
                                        alt="Resultid"
                                    />
                                </a>
                                <p>30-day 100% money back guarantee.</p>
                                <div className="pricing-feature">
                                    <h4>Included Features</h4>
                                    <div className='text-sm text-white mb-3 text-gray-300'>Everything in <b className='text-white'>Starter</b> plus:</div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>50,000</b> tokens
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            Up to <b>200MB</b> of storage across <b>20</b> Documents
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>Export Data</p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>Insights & Trends</b> with{' '}
                                            <b>Advanced</b> Prompts
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>Normal</b> prioritization for GPU
                                            servers
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>10</b> Automated Workflows
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-card pink popular">
                                <div className="most-popular">Most Popular</div>
                                <div className="pricing-image pink">
                                    <img
                                        src="./assets/icons/ufo.svg"
                                        alt="Resultid"
                                    />
                                </div>
                                <h3 className='font-light'>The <b className='font-extrabold'>Doer</b></h3>
                                <p>
                                    Dig, discover, and report on Market Insights
                                    with full featured access and priority
                                </p>
                                <div className="hide">
                                    <h2 className="monthly">
                                        $<span>65</span>/yr
                                    </h2>
                                    <h2 className="annualy hide">
                                        $<span>60</span>/yr
                                    </h2>
                                    <div className="pricing-slider">
                                        <div className="pricing-slider-limit">
                                            <p>20K</p>
                                            <p>100K</p>
                                        </div>
                                        <div className="pricing-slider-wrapper">
                                            <input
                                                type="range"
                                                defaultValue="50000"
                                                step="10000"
                                                min="20000"
                                                max="100000"
                                            />
                                        </div>
                                    </div>
                                    <p className="white">
                                        <span className="bold">50000</span>{' '}
                                        Token/mo
                                    </p>
                                </div>
                                <h2 className="monthly">
                                    $<span>225</span>/mo
                                </h2>
                                <a
                                    className="btn btn-full btn-pink btn-with-icon btn-pricing"
                                    href="http://app.resultid.com/?redirect=settings%2Fpayment"
                                >
                                    <span>Subscribe</span>
                                    <img
                                        src="./assets/icons/arrow-right-white.svg"
                                        alt="Resultid"
                                    />
                                </a>
                                <p>30-day 100% money back guarantee.</p>
                                <div className="pricing-feature">
                                    <h4>Included Features</h4>
                                    <div className='text-sm text-white mb-3 text-gray-300'>Everything in <b className='text-white'>The Viewer</b> plus:</div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>200,000</b> tokens
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            Up to <b>2GB</b> of storage across <b>100</b> Documents
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>Highest</b> prioritization for
                                            GPU servers
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>Priority Email Support</p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>Access to Newest Features</p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>Unlimited</b> Automated Workflows
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>Custom Prompts</b> for AI Writing
                                            Assistance
                                        </p>
                                    </div>
                                    <div className="pricing-feature-list">
                                        <img
                                            src="./assets/icons/check.svg"
                                            alt="Resultid"
                                        />
                                        <p>
                                            <b>
                                                Direct involvement in product
                                                roadmap
                                            </b>{' '}
                                            with early access to beta features
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="home-power" className="pt-10">
                <div className="main-container space-y-10">
                    <div className="section-title text-center">
                        <h2>
                            Power of a{' '}
                            <span className="text-gradient-colorful">
                                10x data science team
                            </span>{' '}
                            in your browser
                        </h2>
                    </div>
                    <p className="text-lg text-white text-center">
                        “No code” is a no-go if you need an expert data
                        scientist to run your data.
                        <br />
                        <strong>
                            We’ve felt that pain so we built better
                        </strong>{' '}
                        to give you the ability to tell a good story.
                    </p>
                    <Accordian></Accordian>
                </div>
            </section>
                </div>
            </section>
            <Faqs></Faqs>
        </div>
    );
}

export default pricingContent;
