import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { isEmail } from 'validator';
import _ from 'lodash';
import { useClipboard } from 'use-clipboard-copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    solid,
    regular,
    light,
    thin,
    duotone,
    icon,
} from '@fortawesome/fontawesome-svg-core/import.macro';

import { UploadProgress } from './UploadProgress';
import {
    submitForm,
    getPresignedUrl,
    downloadSampleData,
    uploadFileToS3,
    sendEmailToHubspot,
} from './../apis';

const NarrativeForm = (props) => {
    const { title, description, prompt, formInputs, sampleData, tutorialData } =
        props;

    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(
        Object.entries(formInputs)
            .map(([key, value]) => ({
                id: key,
                ...value,
            }))
            .concat([
                {
                    id: 'email',
                    type: 'email',
                    value: '',
                    label: 'Email',
                    placeholder: '',
                },
                {
                    id: 'file',
                    type: 'file',
                    value: '',
                    label: 'File',
                    placeholder: '',
                },
            ])
    );
    const [results, setResults] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [errors, setErrors] = useState({});
    const [sampleS3Key, setSampleDataS3Key] = useState(undefined);

    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const [copyText, setCopyText] = useState('Copy to clipboard');
    const { copy, copied } = useClipboard({
        copiedTimeout: 1000,
        results,
    });

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const uploadedFile = acceptedFiles[0];
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'text/csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];

        if (!allowedTypes.includes(uploadedFile.type)) {
            return setErrors({ ...errors, file: 'Invalid file type' });
        }

        setFile(uploadedFile);
        setFormData((prevFormData) =>
            prevFormData.map((input) => {
                if (input.id === 'file') {
                    return { ...input, value: uploadedFile };
                }
                return input;
            })
        );

        let formErrors = { ...errors };
        delete formErrors['file'];
        validateForm(uploadedFile);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'text/csv',
        multiple: false,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) =>
            prevFormData.map((input) => {
                if (input.id === name) {
                    return { ...input, value };
                }
                return input;
            })
        );

        let formErrors = { ...errors };
        if (!value) {
            formErrors[name] = `This field is required`;
        } else if (name === 'email' && !isEmail(value)) {
            formErrors[name] = 'Invalid email address';
        } else if (name === 'file') {
            setFile(value);
        } else {
            delete formErrors[name];
        }

        setErrors(formErrors);
        validateForm();
    };

    const validateForm = (uploadedFile) => {
        let isFormValid = true;
        let formErrors = { ...errors };

        let file = uploadedFile
            ? uploadedFile
            : formData.find((input) => input.id === 'file').value;

        formData.forEach((input) => {
            if (!input.value && input.id !== 'file') {
                isFormValid = false;
            }
        });

        if (!file) {
            isFormValid = false;
        }

        if (Object.keys(formErrors).length > 0) {
            isFormValid = false;
        }

        setIsFormValid(isFormValid);
        if (isFormValid) {
            //console.log('formData', formData);
        }
        return isFormValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        let formErrors = { ...errors };
        formData.forEach((input) => {
            const { id, value } = input;
            if (id === 'file' && sampleS3Key) {
                delete formErrors[id];
            } else if (!value) {
                formErrors[id] = `This field is required`;
            } else if (id === 'email' && !isEmail(value)) {
                formErrors[id] = 'Invalid email address';
            } else if (id === 'file') {
                setFile(value);
            } else {
                delete formErrors[id];
            }
        });

        if (Object.keys(formErrors).length) {
            setErrors(formErrors);
            validateForm();
            setLoading(false);
            return;
        }

        if (!file && !sampleS3Key) {
            setErrors({ ...errors, file: 'This field is required' });
            setLoading(false);
            return;
        }

        let fileName = '';

        if (!sampleS3Key) {
            const {
                data: { output_filename: presignedUrl },
            } = await getPresignedUrl(
                file,
                formData.find((input) => input.id === 'email').value
            );

            fileName = presignedUrl.match(/\/[^\\/]*\?/);

            if (fileName && fileName.length) {
                fileName = fileName[0].slice(1, -1);
            }

            await uploadFileToS3(file, presignedUrl);
        }

        submitForm(
            prompt,
            formData,
            sampleS3Key ? sampleS3Key : `landing_page/${fileName}`,
            sampleS3Key ? tutorialData.name : file.name
        )
            .then((response) => {
                setLoading(false);
                !response.data.overall_synopsis
                    ? setResults(response.data.overall_summary)
                    : setResults(
                          response.data.overall_synopsis.replace(
                              'Generated output:',
                              ''
                          )
                      );
                setShowResults(true);
                sendEmailToHubspot(
                    formData.find((input) => input.id === 'email').value
                );
            })
            .catch((error) => {
                setLoading(false);
                setResults(
                    'An error occured, please try again later: ' + error
                );
            });
    };

    useEffect(() => {
        return () => {
            if (file) {
                URL.revokeObjectURL(file);
            }
        };
    }, [file]);

    const useSampleData = (event) => {
        event.preventDefault();
        setFormData((prevFormData) => {
            if (!prevFormData) return prevFormData;
            const newFormData = Object.values({ ...prevFormData });
            Object.keys(newFormData).forEach((key) => {
                newFormData[key].value = newFormData[key].placeholder;
            });
            return newFormData;
        });

        setSampleDataS3Key(tutorialData.s3Key);
    };

    return (
        <form onSubmit={handleSubmit} className="w-full my-[100px]">
            <section className="w-full mb-[30px]">
                <h1 className="text-3xl font-bold">
                    Try Now:{' '}
                    <span className="text-gradient-colorful">{title}</span>
                </h1>
                <p className="text-gray-300 pt-2">{description}</p>
            </section>
            <section className="w-full">
                <div className="grid grid-cols-1 gap-6 auto-rows-fr md:grid-cols-2">
                    <div className="col">
                        {formData.map((input) => (
                            <div key={input.id}>
                                {!(
                                    input.type === 'file' ||
                                    input.type === 'email'
                                ) && (
                                    <div className="pb-2">
                                        <p
                                            className="font-bold text-base"
                                            htmlFor={input.id}
                                        >
                                            {input.label}
                                        </p>
                                        {input.type === 'text' && (
                                            <input
                                                type={input.type}
                                                name={input.id}
                                                placeholder={input.placeholder}
                                                className={`w-full rounded my-2 text-sm p-2 bg-white/10 text-white ${
                                                    errors[input.id]
                                                        ? 'border border-red-500'
                                                        : ''
                                                }`}
                                                value={input.value}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {input.type === 'textarea' && (
                                            <textarea
                                                type={input.type}
                                                name={input.id}
                                                placeholder={input.placeholder}
                                                className={`w-full rounded my-2 text-sm p-2 bg-white/10 text-white ${
                                                    errors[input.id]
                                                        ? 'border border-red-500'
                                                        : ''
                                                }`}
                                                value={input.value}
                                                onChange={handleChange}
                                                rows="2"
                                            />
                                        )}
                                        {errors[input.id] && (
                                            <p className="text-red-500 text-xs">
                                                {errors[input.id]}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="grid grid-cols-2 gap-6 items-start">
                            <div className="col grow">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                    className={`w-full rounded py-2 my-2 text-sm p-2 bg-white/10 text-white ${
                                        errors.email
                                            ? 'border border-red-500'
                                            : ''
                                    }`}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-xs">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                            <div className="col">
                                <button
                                    className={`
                                        w-full rounded py-2 my-2 text-sm p-2 text-white
                                        ${
                                            loading || showResults
                                                ? 'cursor-not-allowed bg-blue-300'
                                                : 'cursor-pointer bg-blue-500 hover:bg-blue-700'
                                        }
                                    `}
                                    type="submit"
                                    onSubmit={handleSubmit}
                                    disabled={loading || showResults}
                                >
                                    {loading || showResults
                                        ? 'Form Submitted!'
                                        : 'Generate Free Report!'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col grow">
                        <div
                            className={`
                            border-2 border-blue-600 text-gray-300 bg-black/20 shadow-inner-xl rounded-lg p-4 w-full grow h-full shadow-lg
                            ${showResults ? 'block' : 'hidden'}
                        `}
                        >
                            <div className="flex justify-start items-center mb-2">
                                <h1 className="font-bold text-lg">Result</h1>
                                <button
                                    onClick={copy}
                                    className="hidden text-sm border-2 border-blue-900 py-1 px-1.5 rounded hover:bg-blue-900"
                                >
                                    <FontAwesomeIcon
                                        icon={solid('copy')}
                                        className="text-sm mr-1"
                                    />
                                    {copied ? 'Copied!' : 'Copy'}
                                </button>
                            </div>
                            <div className="text-sm text-white">
                                <strong>
                                    Unlock the full potential of our platform!
                                </strong>{' '}
                                Sign in now to access powerful tools and
                                analysis beyond this sneak peek.
                            </div>
                            <a href="https://app.resultid.com/login">
                                <button className="text-white text-sm bg-blue-600 rounded p-2 w-full my-4 mb-4 hover:bg-blue-500 text-center font-bold">
                                    Let's get started
                                </button>
                            </a>
                            <p
                                className="text-sm text-gray-300"
                                onChange={(e) => setCopyText(results)}
                            >
                                {results && results}
                            </p>
                        </div>
                        {loading ? (
                            <div
                                className={`
                                border border-white/20 bg-black/20 shadow-inner-xl rounded-lg px-3 py-1 border-dashed flex items-center justify-center w-full
                                ${errors.file ? 'border-red-500' : ''}
                                ${showResults ? 'hidden' : 'block'}
                            `}
                            >
                                <div className="items-center justify-center text-center w-full">
                                    <div className="flex items-center text-center pt-3 px-4">
                                        <FontAwesomeIcon
                                            icon={solid('spinner')}
                                            className="text-2xl justify-center mr-2"
                                            spin
                                        />
                                        <p className="font-bold">
                                            Please wait while we generate your
                                            results!
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            paddingBottom: '62.5%',
                                            height: 0,
                                        }}
                                        className="m-3"
                                    >
                                        <iframe
                                            title="Voice of Customer"
                                            src="https://www.loom.com/embed/028654114615475a8f15d24125123bab?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&autoplay=1"
                                            autoPlay
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`
                                col h-full flex flex-col justify-center items-center pb-2
                                ${showResults ? 'hidden' : 'block'}
                            `}
                            >
                                <div className="flex items-center w-full gap-2 mb-2">
                                    <button
                                        className="p-2 rounded bg-blue-900 border-2 border-blue-900 hover:border-blue-800 hover:bg-blue-800 w-full text-white text-sm"
                                        onClick={useSampleData}
                                    >
                                        <strong>Use sample form</strong>{' '}
                                    </button>
                                    <a
                                        className="p-2 text-sm text-blue-300 border-2 border-blue-300 rounded w-full font-bold hover:bg-blue-300/10 text-center"
                                        href={sampleData.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        View sample data:{' '}
                                        <span className="text-white/80">
                                            {sampleData.name}
                                        </span>
                                        <FontAwesomeIcon
                                            icon={solid('file-alt')}
                                            className="text-sm ml-1"
                                        />
                                    </a>
                                </div>
                                <div
                                    {...getRootProps()}
                                    className={`
                                    border border-white/20 bg-black/20 shadow-inner-xl rounded-lg px-3 py-1 border-dashed flex items-center justify-center w-full cursor-pointer grow
                                    ${errors.file ? 'border-red-500' : ''}
                                `}
                                >
                                    <div>
                                        {sampleS3Key && (
                                            <div className="flex flex-col items-center">
                                                <UploadProgress
                                                    success={true}
                                                    fileName={tutorialData.name}
                                                    fileSize={0}
                                                />
                                                <button
                                                    className="bg-primary-alert text-white rounded px-3 py-1.5 mr-2 text-sm flex justify-center items-center mt-3"
                                                    disabled
                                                >
                                                    Sample Data Uploaded
                                                    <img
                                                        src="/assets/checked.svg"
                                                        className="w-[20px] ml-2"
                                                        alt="check"
                                                    />
                                                </button>
                                            </div>
                                        )}
                                        {!sampleS3Key && (
                                            <input {...getInputProps()} />
                                        )}
                                        {file && !sampleS3Key && (
                                            <div>
                                                <span className=" p-5 mb-2 flex flex-col text-center items-center">
                                                    <span className="flex items-center">
                                                        <FontAwesomeIcon
                                                            icon={solid(
                                                                'circle-check'
                                                            )}
                                                            className="text-xl text-green-300 mr-1"
                                                        />
                                                        <strong>
                                                            Successfully
                                                            Uploaded:
                                                        </strong>
                                                    </span>
                                                    <span className="text-gray-300">
                                                        {file.name}
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                        {!file && !sampleS3Key && (
                                            <div className="flex w-full justify-center grid grid-cols-1">
                                                <p className="m-2 rounded-lg p-3 flex items-center justify-center w-full cursor-pointer h-full grow">
                                                    <FontAwesomeIcon
                                                        icon={solid('files')}
                                                        className="text-4xl justify-center"
                                                    />
                                                </p>
                                                <p className="m-2 rounded-lg p-3 flex flex-col items-center justify-center w-full cursor-pointer h-full grow text-white">
                                                    <span>
                                                        Drag and drop a file
                                                        here, or click to select
                                                        a file
                                                    </span>
                                                    <strong className="text-gray-400">
                                                        Max file size: 5MB
                                                    </strong>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {errors.file && (
                            <p className="text-red-500 text-xs">
                                {errors.file}
                            </p>
                        )}
                    </div>
                </div>
            </section>
        </form>
    );
};

export default NarrativeForm;
