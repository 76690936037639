import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

import { useParams } from 'react-router-dom';
import ProductManager from './content/ProductManager';
import TechTransfer from './content/TechTransfer';

function Product() {
    let { type } = useParams();

    return (
        <div>
            <Header />
            {type === 'universities' && <TechTransfer />}
            {type === 'product' && <ProductManager />}
            <Footer />
        </div>
    );
}
export default Product;
