import React, { useState, useEffect } from 'react';
import './../../assets/css/styles.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import he from 'he';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function BlogPosts() {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        fetch('https://resultidai.wpcomstaging.com/wp-json/wp/v2/posts?_embed&per_page=100')
            .then((response) => response.json())
            .then((data) => {
                setPosts(data);
                setIsLoading(false);
            });
    }, []);

    const { slug } = useParams();

    useEffect(() => {
        const post = posts.find((post) => post.slug === slug);
        if (post) {
            setSelectedPost(post);
            setIsModalOpen(true);
        }
    }, [posts, slug]);

    const openModal = (post) => {
        setSelectedPost(post);
        setIsModalOpen(true);
        navigate(`/blog/${post.slug}`);
    };

    const closeModal = () => {
        setSelectedPost(null);
        setIsModalOpen(false);
        navigate(`/blog`);
    };

    useEffect(() => {
        const handlePopstate = () => {
            if (isModalOpen) {
                closeModal();
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => window.removeEventListener('popstate', handlePopstate);
    }, [isModalOpen]);

    return (
        <div>
            <div className="section-title pb-[50px] text-center">
                <h2>
                    Welcome to the{' '}
                    <span className="text-gradient-colorful">
                        Resultid Blog
                    </span>
                    <p className="pt-8 text-base">
                        <strong>Welcome to our company blog!</strong> Our blog
                        is where we share
                        <br />
                        our journey, platform tips, and thought leadership.
                    </p>
                </h2>
            </div>
                {isLoading ? (
                    <div className='h-screen w-full items-center text-center'>
                        <FontAwesomeIcon icon={solid('spinner-third')} spin size="3x" />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                {posts.map((post) => (
                    <div
                        className="flex bg-slate-800 rounded overflow-hidden shadow-2xl"
                        key={post.id}
                    >
                        {post._embedded &&
                            post._embedded['wp:featuredmedia'] &&
                            post._embedded['wp:featuredmedia'][0]
                                .source_url && (
                                <div className="flex md:flex-none w-48 relative">
                                    <img
                                        src={
                                            post._embedded[
                                                'wp:featuredmedia'
                                            ][0].source_url
                                        }
                                        alt={post.title.rendered}
                                        className="absolute inset-0 w-full h-full object-cover"
                                    />
                                </div>
                            )}
                        <div className="flex-auto p-6">
                            <div className="flex flex-wrap">
                                <h1
                                    className="flex-auto text-xl font-bold text-white cursor-pointer"
                                    onClick={() => openModal(post)}
                                >
                                    {he.decode(post.title.rendered)}
                                </h1>
                                <div className="text-sm text-slate-400 py-2">
                                    {he.decode(
                                        post.content.rendered
                                            .replace(/(<([^>]+)>)/gi, '')
                                            .substring(0, 200) + '...'
                                    )}
                                </div>
                                <div className="text-xs text-slate-500 py-2">
                                    Published on{' '}
                                    <strong className="text-white/50">
                                        {new Date(
                                            post.date
                                        ).toLocaleDateString()}
                                    </strong>
                                </div>
                            </div>
                            <div className="flex space-x-4 py-4 text-sm font-medium">
                                <div className="flex-auto flex space-x-4">
                                    <button
                                        className="h-10 px-6 font-semibold rounded-md bg-black hover:bg-black/50 text-white cursor-pointer flex items-center"
                                        onClick={() => openModal(post)}
                                        type="button"
                                    >
                                        Read On{' '}
                                        <FontAwesomeIcon
                                            icon={solid('book')}
                                            className="ml-2 text-slate-400 hover:text-slate-200 transition duration-300 ease-in-out"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                )}
            <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
                {selectedPost &&
                    (console.log(selectedPost),
                    (
                        <div className="blog-post flex flex-col justify-center items-center overflow-hidden">
                            <div className="flex items-start justify-between w-full px-4 pt-2">
                                <div className="header-logo">
                                    <a href="/">
                                        <img
                                            src="./../assets/images/dark-logo.svg"
                                            alt="Logo"
                                        />
                                    </a>
                                </div>
                                <button
                                    onClick={closeModal}
                                    className="cursor-pointer"
                                >
                                    <FontAwesomeIcon
                                        icon={solid('xmark')}
                                        className="text-gray-300 hover:text-black text-2xl transition duration-300 ease-in-out"
                                    />
                                </button>
                            </div>
                            <div className=" mt-[2.5em] mb-4 lg:max-w-[720px] w-full">
                                <h2 className="font-bold text-4xl">
                                    {he.decode(selectedPost.title.rendered)}
                                </h2>{' '}
                                <div className="text-xs text-black/50 py-1">
                                    Published on{' '}
                                    <strong className="text-black">
                                        {new Date(
                                            selectedPost.date
                                        ).toLocaleDateString()}
                                    </strong>
                                </div>
                            </div>
                            <div
                                className="lg:max-w-[900px] w-full relative"
                                dangerouslySetInnerHTML={{
                                    __html: selectedPost.content.rendered,
                                }}
                            />
                        </div>
                    ))}
            </Modal>
        </div>
    );
}

export default BlogPosts;
