import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faLanguage,
    faLock,
    faChartLine,
    faBullhorn,
    faRobot,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import './../assets/css/styles.css';

const Accordion = () => {
    const [progress, setProgress] = useState(0);
    const [activeTab, setActiveTab] = useState('trend');
    const [isAutoRotatePaused, setIsAutoRotatePaused] = useState(false);

    const tabs = [
        {
            id: 'trend',
            tabTitle: 'Track Emerging Trends',
            title: 'Track Trends in Text Data Over Time',
            icon: faBullhorn,
            content: `Resultid allows organizations to track and monitor emerging trends within their text data over time. By identifying patterns and changes in areas like sentiment, businesses can stay ahead of evolving market dynamics and make proactive decisions.`,
        },
        {
            id: 'automation',
            tabTitle: 'Leverage Automation',
            title: 'Leverage Automation and Workflows for Efficient Data Analysis',
            icon: faRobot,
            content: `Resultid leverages automation to streamline data analysis processes. By automating repetitive tasks and workflows, enterprises can save time, increase efficiency, and scale their analytical capabilities, allowing users to focus on higher-value tasks and derive insights faster.`,
        },
        {
            id: 'dataqa',
            tabTitle: 'Data Q&A',
            title: 'Ask Questions and Query Data Across the Organization',
            icon: faQuestionCircle,
            content: `Resultid facilitates interactive Q&A sessions with your data, allowing users to ask questions and receive insights in a conversational manner. This intuitive interface enhances the accessibility and usability of data analysis, enabling users to explore their data effortlessly.`,
        },
        {
            id: 'consistency',
            tabTitle: 'Promote Consistency',
            title: 'Promote Consistency Across Departments, Hierarchy, and Geography',
            icon: faCopy,
            content: `Resultid helps enterprises maintain consistency by providing tools to align data interpretation across departments, geography, and hierarchy. This ensures a unified understanding and enables better decision-making based on consistent insights.`,
        },
        {
            id: 'multilingual',
            tabTitle: 'Multilingual Support',
            title: 'Support for Over 50+ Languages',
            icon: faLanguage,
            content: `Resultid offers robust multilingual support, allowing organizations to analyze text data in various languages, enabling a global perspective and ensuring no language barrier hinders the extraction of valuable insights.`,
        },
        {
            id: 'secure',
            tabTitle: 'GPT for Enterprise',
            title: 'Securely Connect Your Data to ChatGPT',
            icon: faLock,
            content: `Resultid provides a secure connection for enterprises to integrate their data with ChatGPT, ensuring confidentiality and data privacy while leveraging the power of AI-driven analysis for actionable insights.`,
        },
        {
            id: 'combined',
            tabTitle: 'Data-Driven Insights',
            title: 'Uncover Novel Insights by Combining Qualitative and Quantitative Data',
            icon: faChartLine,
            content: `Resultid empowers enterprises to combine qualitative and quantitative data, unlocking unique insights that result from the convergence of textual and numerical information. This integration offers a holistic view for better decision-making.`,
        },
    ];

    const intervalDuration = 6000; // Interval duration in milliseconds

    useEffect(() => {
        let intervalId = null;

        const startAutoRotate = () => {
            intervalId = setInterval(() => {
                if (!isAutoRotatePaused) {
                    const currentIndex = tabs.findIndex(
                        (tab) => tab.id === activeTab
                    );
                    const nextIndex = (currentIndex + 1) % tabs.length;
                    setActiveTab(tabs[nextIndex].id);
                }
            }, intervalDuration);
        };

        startAutoRotate();

        return () => {
            clearInterval(intervalId);
        };
    }, [activeTab, isAutoRotatePaused, tabs]);

    useEffect(() => {
        let intervalId = null;

        const startProgress = () => {
            setProgress(0);
            const startTime = Date.now();

            const updateProgress = () => {
                const elapsedTime = Date.now() - startTime;
                const progressValue = (elapsedTime / intervalDuration) * 100;

                if (progressValue >= 100) {
                    setProgress(100);
                    clearInterval(intervalId);
                    setTimeout(() => {
                        const currentIndex = tabs.findIndex(
                            (tab) => tab.id === activeTab
                        );
                        const nextIndex = (currentIndex + 1) % tabs.length;
                        setActiveTab(tabs[nextIndex].id);
                        startProgress(); // Restart the progress bar
                    }, 10); // Wait before switching to the next tab
                } else {
                    setProgress(progressValue);
                }
            };

            intervalId = setInterval(updateProgress, 10);
        };

        startProgress();

        return () => {
            clearInterval(intervalId);
        };
    }, [activeTab]);

    const handleTabClick = (index) => {
        setActiveTab(index);
        setIsAutoRotatePaused(true);
        setTimeout(() => {
            setIsAutoRotatePaused(false);
        }, 10000);
    };

    return (
        <div className="flex md:flex-row flex-col rounded overflow-hidden">
            <div className="flex flex-col h-full">
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`p-4 cursor-pointer flex-none min-w-[250px] ${
                            activeTab === tab.id
                                ? 'bg-gradient-to-r from-cyan-500 to-blue-500 text-white'
                                : 'text-white/50'
                        }`}
                        onClick={() => handleTabClick(tab.id)}
                    >
                        <div className="flex items-center font-bold transition-all">
                            <FontAwesomeIcon
                                icon={tab.icon}
                                className={`mr-2 text-sm ${
                                    activeTab === tab.id
                                        ? 'text-blue-900'
                                        : 'text-white/20'
                                }`}
                            />
                            <p>{tab.tabTitle}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex-grow">
                {!isAutoRotatePaused && (
                    <div className="h-1">
                        <div
                            className="h-full bg-blue-500 animate-progress"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                )}
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`py-4 home-power-tab-text h-full grid grid-cols-1 md:grid-cols-2 ${
                            activeTab === tab.id ? 'block' : 'hidden'
                        }`}
                    >
                        <div className="space-y-8">
                            <h3 className="text-2xl font-bold text-white">
                                {tab.title}
                            </h3>
                            <p className="mt-2 font-regular text-gray-300">
                                {tab.content}
                            </p>
                            <a
                                className="mt-4 inline-block px-4 py-2 text-sm font-bold bg-blue-500 text-white rounded hover:bg-blue-600"
                                href="https://app.resultid.com"
                            >
                                Try for Free!
                            </a>
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src={`./assets/images/${tab.id}.png`}
                                alt={tab.id}
                                className="w-full md:w-auto md:h-80 ml-0 mt-6 md:mt-0 md:ml-5 rounded-lg shadow"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Accordion;
