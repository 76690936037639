/* eslint-disable no-nested-ternary */
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { truncateText } from '../utils';

import { Button } from './button';

export const UploadProgress = (props) => {
    return (
        <div className="grow flex flex-col items-center justify-center cursor-auto">
            <div
                className={`p-6 border-8 rounded-full border-primary-900 w-[240px] h-[240px]
      ${
          props.success === true
              ? ''
              : props.success === false
              ? 'border-pink-100'
              : 'border-dashed'
      }`}
            >
                <div
                    className={` w-full h-full rounded-full bg-primary-900/20 flex items-center justify-center text-white`}
                >
                    <span className="text-7xl font-dm-sans">
                        {props.success === true ? (
                            <FontAwesomeIcon icon={solid('check')} />
                        ) : props.success === false ? (
                            <div className="flex items-center flex-col">
                                <FontAwesomeIcon
                                    icon={solid('bell-on')}
                                    className="text-primary-alert"
                                />
                            </div>
                        ) : (
                            props.progress + '%'
                        )}
                    </span>
                </div>
            </div>
            {props.success !== false ? (
                <>
                    <div className="rounded-2xl px-7 py-2 bg-primary-900 -mt-6">
                        <img src="/assets/csv_file.svg" alt="csv_file" />
                    </div>
                    <span
                        data-tip={props.fileName}
                        title={props.fileName}
                        className="mt-8"
                    >
                        {truncateText(props.fileName, 20)}
                    </span>
                    {/* <div className="text-lg text-gray-500 whitespace-nowrap mt-3">
                        {props.fileSize}
                    </div> */}
                </>
            ) : (
                <>
                    <p className="text-xl font-bold mt-4">Oops!</p>
                    {props.errorMessage === 'filename_error' ? (
                        <p className="text-md">
                            You already have a file named{' '}
                            <span className="font-semibold">
                                {props.fileName}
                            </span>
                            !
                        </p>
                    ) : (
                        <p className="text-md">Something went wrong!</p>
                    )}
                    <div className="flex items-center justify-center">
                        {props.handleReupload && (
                            <Button
                                className="px-4 py-2 rounded-3xl mt-8 bg-primary-900 hover:bg-primary-900/50 text-sm "
                                onClick={() => {
                                    props.handleReupload &&
                                        props.handleReupload();
                                }}
                            >
                                <span>Re-upload</span>
                                <FontAwesomeIcon
                                    icon={regular('upload')}
                                    className="ml-2"
                                />
                            </Button>
                        )}
                        {props.handleReupload &&
                            props.errorMessage === 'filename_error' && (
                                <Button
                                    className="px-4 py-2 rounded-3xl mt-8 bg-primary-alert hover:bg-primary-alert/50 text-sm ml-5"
                                    onClick={() => {
                                        props.overWrite && props.overWrite();
                                    }}
                                >
                                    <span>Overwrite File</span>
                                    <FontAwesomeIcon
                                        icon={regular('upload')}
                                        className="ml-2"
                                    />
                                </Button>
                            )}
                    </div>
                </>
            )}
        </div>
    );
};
