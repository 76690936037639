import React from 'react';
import Header from './../components/header';
import Footer from './../components/footer';

function TermsOfUse() {
    return (
        <>
            <Header />
            <div className="main-content mt-[110px] mb-[30px]">
                <div className="main-container grid grid-cols-1 flex text-gray-300 mt-1 py-3">
                    <h1 className="text-3xl font-bold mb-4 text-white">
                        Terms of Use
                    </h1>
                    <p className="py-1 text-sm">
                        Welcome to Resultid! Please read the following terms and
                        conditions carefully, as they govern your use of our
                        platform. By accessing or using the platform, you agree
                        to be bound by these terms and conditions.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Use of the Platform
                    </h2>
                    <p className="py-1 text-sm">
                        You may use the platform only for lawful purposes and in
                        accordance with these terms and conditions. You agree
                        not to use the platform:
                    </p>
                    <ul className="text-sm list-disc ml-5">
                        <li>
                            In any way that violates any applicable federal,
                            state, local, or international law or regulation.
                        </li>
                        <li>
                            For the purpose of exploiting, harming, or
                            attempting to exploit or harm minors in any way by
                            exposing them to inappropriate content, asking for
                            personally identifiable information, or otherwise.
                        </li>
                        <li>
                            To transmit, or procure the sending of, any
                            advertising or promotional material, including any
                            "junk mail," "chain letter," "spam," or any other
                            similar solicitation.
                        </li>
                        <li>
                            To impersonate or attempt to impersonate the
                            Company, a Company employee, another user, or any
                            other person or entity.
                        </li>
                        <li>
                            To engage in any other conduct that restricts or
                            inhibits anyone's use or enjoyment of the platform,
                            or which, as determined by the Company, may harm the
                            Company or users of the platform or expose them to
                            liability.
                        </li>
                    </ul>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Intellectual Property
                    </h2>
                    <p className="py-1 text-sm">
                        The platform and its original content, features, and
                        functionality are and will remain the exclusive property
                        of the Company and its licensors. The platform is
                        protected by copyright, trademark, and other laws of
                        both the United States and foreign countries. Our
                        trademarks and trade dress may not be used in connection
                        with any product or service without the prior written
                        consent of the Company.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Disclaimer of Warranties
                    </h2>
                    <p className="py-1 text-sm">
                        The platform is provided "as is" and "as available"
                        without warranties of any kind, either express or
                        implied, including but not limited to warranties of
                        merchantability, fitness for a particular purpose, and
                        non-infringement. The Company does not warrant that the
                        platform will be uninterrupted or error-free, and the
                        Company will not be liable for any interruptions or
                        errors. You understand and agree that you use the
                        platform at your own discretion and risk.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Limitation of Liability
                    </h2>
                    <p className="py-1 text-sm">
                        In no event will the Company, its affiliates, or their
                        licensors, service providers, employees, agents,
                        officers, or directors be liable for damages of any
                        kind, under any legal theory, arising out of your use,
                        or inability to use, the platform, including any direct,
                        indirect, incidental, special, consequential, or
                        punitive damages, including but not limited to, personal
                        injury, pain and suffering, emotional distress, loss of
                        revenue, loss of profits, loss of business or
                        anticipated savings, loss of use, loss of goodwill, loss
                        of data, and whether caused by tort (including
                        negligence), breach of contract, or otherwise, even if
                        foreseeable.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Indemnification
                    </h2>
                    <p className="py-1 text-sm">
                        You agree to defend, indemnify, and hold harmless the
                        Company, its affiliates, licensors, and service
                        providers, and its and their respective officers,
                        directors, employees, contractors, agents, licensors,
                        suppliers, successors, and assigns from and against any
                        claims, liabilities, damages, judgments, awards, losses,
                        costs, expenses, or fees (including reasonable
                        attorneys' fees) arising out of or relating to your
                        violation of these terms and conditions or your use of
                        the platform, including, but not limited to, User
                        Content submitted by you, your use of the platform's
                        content, services, and products, or your use of any
                        information obtained from the platform.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Modifications to these Terms
                    </h2>
                    <p className="py-1 text-sm">
                        The Company reserves the right to modify these terms and
                        conditions at any time. Such modifications shall be
                        effective immediately upon posting of the modified terms
                        and conditions on the platform. Your continued use of
                        the platform after any such modification indicates your
                        acceptance of the new terms and conditions.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Governing Law
                    </h2>
                    <p className="py-1 text-sm">
                        These terms and conditions shall be governed by and
                        construed in accordance with the laws of the State of
                        [STATE], and the United States, without giving effect to
                        any principles of conflicts of law. You agree that any
                        action at law or in equity arising out of or relating to
                        these terms and conditions shall be filed only in the
                        state and federal courts located in [STATE] and you
                        hereby consent and submit to the personal jurisdiction
                        of such courts for the purposes of litigating any such
                        action.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        User Content
                    </h2>
                    <p className="py-1 text-sm">
                        The platform may allow you to submit, upload, publish,
                        or otherwise make available content, including but not
                        limited to text, photographs, videos, and audio
                        (collectively, "User Content"). You retain all rights
                        in, and are solely responsible for, the User Content you
                        make available through the platform.
                    </p>
                    <p className="py-1 text-sm">
                        By making any User Content available through the
                        platform, you grant to the Company a non-exclusive,
                        transferable, sub-licensable, royalty-free, worldwide
                        license to use, copy, modify, create derivative works
                        based on, distribute, publicly display, publicly
                        perform, and otherwise exploit in any manner such User
                        Content in all formats and distribution channels now
                        known or hereafter devised (including in connection with
                        the platform and the Company's business and on
                        third-party sites and services), without further notice
                        to or consent from you, and without the requirement of
                        payment to you or any other person or entity.
                    </p>
                    <p className="py-1 text-sm">
                        You represent and warrant that: (i) you either are the
                        sole and exclusive owner of all User Content or you have
                        all rights, licenses, consents, releases, and
                        permissions necessary to grant the rights granted in
                        these terms and conditions, and (ii) neither the User
                        Content, your submission, uploading, publishing, or
                        otherwise making available of such User Content, nor the
                        Company's use of the rights granted herein will
                        infringe, misappropriate, or violate a third party's
                        patent, copyright, trademark, trade secret, moral
                        rights, or proprietary or other proprietary rights, or
                        result in the violation of any applicable law or
                        regulation.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        User Conduct
                    </h2>
                    <p className="py-1 text-sm">
                        You agree that you will not engage in any activity that
                        interferes with or disrupts the platform (or the servers
                        and networks which are connected to the platform).
                    </p>
                    <p className="py-1 text-sm">
                        You understand that when using the platform, you will be
                        exposed to User Content from a variety of sources, and
                        that the Company is not responsible for the accuracy,
                        usefulness, safety, or intellectual property rights of
                        or relating to such User Content. You further understand
                        and acknowledge that you may be exposed to User Content
                        that is inaccurate, offensive, indecent, or
                        objectionable, and you agree to waive, and hereby do
                        waive, any legal or equitable rights or remedies you
                        have or may have against the Company with respect
                        thereto.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Termination
                    </h2>
                    <p className="py-1 text-sm">
                        The Company may, in its sole discretion, at any time
                        terminate or suspend all or a portion of the platform,
                        or your use of all or a portion of the platform, with or
                        without notice and with or without cause.
                    </p>
                    <p className="py-1 text-sm">
                        Upon termination or suspension, regardless of the
                        reasons therefore, your right to use the platform will
                        immediately cease, and you acknowledge and agree that
                        the Company may immediately deactivate or delete your
                        account and all related information and files in your
                        account and/or bar any further access to such files or
                        the platform. The Company shall not be liable to you or
                        any third party for any claims or damages arising out of
                        any termination or suspension or any other actions taken
                        by the Company in connection therewith.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Third-Party Links & Services
                    </h2>
                    <p className="py-1 text-sm">
                        The platform may contain links to third-party websites,
                        services, or resources. You acknowledge and agree that
                        the Company is not responsible or liable for: (i) the
                        availability or accuracy of such websites, services, or
                        resources; or (ii) the content, products, or services on
                        or available from such websites, services, or resources.
                        Links to such websites, services, or resources do not
                        imply any endorsement by the Company of such websites,
                        services, or resources or the content, products, or
                        services available from such websites, services, or
                        resources. You acknowledge sole responsibility for and
                        assume all risk arising from your use of any such
                        websites, services, or resources.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Privacy Policy
                    </h2>
                    <p className="py-1 text-sm">
                        The Company's Privacy Policy, as it may be updated from
                        time to time, is hereby incorporated into these terms
                        and conditions. The Privacy Policy can be found at{' '}
                        <a className="text-blue-600" href="/privacy">
                            Privacy Policy
                        </a>
                        .
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        General
                    </h2>
                    <p className="py-1 text-sm">
                        These terms and conditions, together with the Privacy
                        Policy and any additional terms to which you agree when
                        using particular elements of the platform, constitute
                        the entire agreement between you and the Company with
                        respect to the use of the platform.
                    </p>
                    <p className="py-1 text-sm">
                        The failure of the Company to exercise or enforce any
                        right or provision of these terms and conditions shall
                        not constitute a waiver of such right or provision.
                    </p>
                    <p className="py-1 text-sm">
                        If any provision of these terms and conditions is found
                        to be invalid, the parties nevertheless agree that the
                        court should endeavor to give effect to the parties'
                        intentions as reflected in the provision, and the other
                        provisions of these terms and conditions remain in full
                        force and effect.
                    </p>
                    <p className="py-1 text-sm">
                        You agree that regardless of any statute or law to the
                        contrary, any claim or cause of action arising out of or
                        related to use of the platform or these terms and
                        conditions must be filed within one (1) year after such
                        claim or cause of action arose or be forever barred.
                    </p>
                    <p className="py-1 text-sm">
                        The section titles in these terms and conditions are for
                        convenience only and have no legal or contractual
                        effect.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Miscellaneous
                    </h2>
                    <p className="py-1 text-sm">
                        These terms and conditions constitute the entire
                        agreement between you and the Company regarding the use
                        of the platform. If any provision of these terms and
                        conditions is held to be invalid or unenforceable, such
                        provision shall be struck and the remaining provisions
                        shall be enforced. The failure of the Company to enforce
                        any right or provision in these terms and conditions
                        shall not constitute a waiver of such right or provision
                        unless acknowledged and agreed to by us in writing.
                    </p>
                    <p className="py-1 text-sm">
                        In the event of any conflict between these terms and
                        conditions and any contract you have with the Company,
                        the terms of that contract will control.
                    </p>
                    <p className="py-1 text-sm">
                        These terms and conditions do not create any agency,
                        partnership, joint venture, or employment relationship,
                        and you may not make any representation on our behalf.
                    </p>
                    <p className="py-1 text-sm">
                        You may not assign these terms and conditions or
                        transfer any rights to use the platform without the
                        prior written consent of the Company, and any attempted
                        assignment without such consent will be null and void.
                    </p>
                    <h2 className="text-md font-semibold mt-4 mb-1 text-white">
                        Contact Information
                    </h2>
                    <p className="py-1 text-sm">
                        If you have any questions about these terms and
                        conditions, please contact us at{' '}
                        <a
                            className="text-blue-600"
                            href="mailto:info@resultid.com"
                        >
                            info@resultid.com
                        </a>
                        .
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TermsOfUse;
