import { React, useState, useEffect } from 'react';
import './../assets/css/styles.css';
import './../assets/css/header.css';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';

import Dropdown from './dropdown';

function Header() {
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        const handleClick = (e) => {
            // if the click is outside of the products menu, close the menu
            if (!e.target.closest('.products-menu')) {
                setIsActive(false);
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [isActive]);

    return (
        <header>
            <div className="main-container">
                <div className="header-wrapper">
                    <div className="header-logo">
                        <a href="/">
                            <img src="./../assets/images/logo.svg" alt="Logo" />
                        </a>
                    </div>
                    <div className="header-menu">
                        <div className="menu-mobile">
                            <span> </span>
                            <span></span>
                        </div>
                        <div className="header-menu-wrapper">
                            <div className="close-mobile">
                                <span> </span>
                                <span></span>
                            </div>
                            <nav>
                                <ul className="text-sm text-white font-semibold flex items-start">
                                    <li
                                        className={`mt-1 ${
                                            location.pathname === '/product' ||
                                            location.pathname ===
                                                '/product/universities'
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <Dropdown
                                            title="Product Hubs"
                                            options={[
                                                'Product Development',
                                                'Technology Transfer',
                                            ]}
                                            links={[
                                                '/product/product',
                                                '/product/universities',
                                            ]}
                                        />
                                    </li>
                                    <li
                                        className={`${
                                            location.pathname === '/blog'
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <a href="/blog">Blog</a>
                                    </li>
                                    <li
                                        className={`${
                                            location.pathname === '/pricing'
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <a href="/pricing">Pricing</a>
                                    </li>
                                    <li>
                                        <a href="https://app.resultid.com">
                                            Login
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="border-2 rounded border-pink-400 text-pink-400 px-4 py-2 hover:text-white hover:bg-pink-500"
                                            href="https://app.resultid.com"
                                        >
                                            Try For Free!
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
