import React from 'react';
import './../assets/css/styles.css';

function Footer() {
    return (
        <footer>
            <div className="main-container">
                <div className="footer-wrapper">
                    <div className="footer-logo">
                        <a href="/">
                            {' '}
                            <img
                                src="./../assets/images/logo.svg"
                                alt="Resultid"
                            />
                        </a>
                    </div>
                    <div className="footer-menu">
                        <div className="footer-menu-group">
                            <h3>Featured</h3>
                            <ul>
                                <li>
                                    <a href="/">Narratives</a>
                                </li>
                                <li>
                                    <a href="/">Data Storytelling</a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/building-a-platform-that-scales-with-you-4666ac2078fb">
                                        Alignment
                                    </a>
                                </li>
                                <li>
                                    <a href="/">Instruction Following</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-menu-group">
                            <h3>App</h3>
                            <ul>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/resultid-beta-launch-purposeful-storytelling-isnt-show-business-it-s-good-business-b62cdb191660">
                                        Overview
                                    </a>
                                </li>
                                <li>
                                    <a href="/pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog">
                                        Examples
                                    </a>
                                </li>
                                <li>
                                    <a href="/">Docs</a>
                                </li>
                                <li>
                                    <a href="/privacy">Terms &amp; Policies</a>
                                </li>
                                <li>
                                    <a href="/">Status</a>
                                </li>
                                <li>
                                    <a href="https://app.resultid.com">
                                        Log in
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-menu-group">
                            <h3>Blog</h3>
                            <ul>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog">
                                        Index
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/future-of-work/home">
                                        Future of Work
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/reflections/home">
                                        Reflections
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/data-stories/home">
                                        Research
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-menu-group">
                            <h3>Information</h3>
                            <ul>
                                <li>
                                    <a href="https://www.linkedin.com/company/resultid">
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/">Investor Relations</a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog/data-stories/home">
                                        Our Research
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog">
                                        Publications
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/the-resultid-blog">
                                        Newsroom
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/resultid/jobs/">
                                        Career
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-copy">
                        <p>
                            <strong>Resultid</strong> &copy; 2022
                        </p>
                        <ul>
                            <li>
                                <a href="/privacy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
