import React from 'react';
import Header from './../components/header';
import Main from './../components/main';
import Footer from './../components/footer';

function Home() {
    return (
        <div>
            <Header />
            <Main />
            <Footer />
        </div>
    );
}
export default Home;
