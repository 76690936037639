import { React, useState } from 'react';
import AccordianList from './accordianList';

function Faqs() {
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    const items = [
        {
            title: `How much data can I upload?`,
            content: `Oh, you're still a starter user? Well that's ok! You can still upload a total of 3 documents, and each document can be a maximum of 5 MB. When you upgrade to Pro you can upload 20 documents with a max size of 10 MB each. And if you wanna be a real Champion, well you can upload as many documents as you want with no limit on file size. Now that's a true Champion!`,
        },
        {
            title: `Can I cancel, upgrade, or downgrade anytime?`,
            content: `You're the boss! Make changes to your plan whenever you want — you are always in control. You can downgrade, upgrade and cancel your plan at any time. We are going to give you every reason to upgrade 👏`,
        },
        {
            title: `Is my data relevant for Resultid?`,
            content: `Data is our best friend here at Resultid and we’re built to make the magic happen with all of your data types! All files supported including csv, docs, pptx, pdf, xlsx and txt and remember, it’s that messy unstructured qualitative data that you want help with!`,
        },
        {
            title: `Does Resultid work with large companies?`,
            content: `Large companies aren’t intimidating - we’re here to help you get over that misconception 🙂 We’re the (perfect) solution for those demanding enterprise needs. Who says you can’t have your cake and eat it too?`,
        },
        {
            title: `What is priority queuing?`,
            content: `Priority queuing is just a fancy way of saying that when it comes to our servers, paid users get to cut the line. Everyone gets the same quality and since the paid users are keeping our lights on, we like to keep them happy.
        {' '}`,
        },
        {
            title: `What do Unlimited Plans include?`,
            content: `Unlimited plans allow any number of files of any size to be uploaded and processed.`,
        },
        {
            title: `Does Resultid integrate with my existing workflow?`,
            content: `Keep doing what you are doing! Our human-in-the-loop approach means we learn from you without forcing a change in your game. Let’s accelerate your work so you stay in flow without changing the way your work is done.`,
        },
    ];

    return (
        <section className="section-faq">
            <div className="main-container">
                <div className="section-title text-center">
                    <h2>
                        Frequently asked{' '}
                        <span className="text-gradient-colorful">
                            questions
                        </span>
                    </h2>
                </div>
                <div className="pricing-faq">
                    <div className="pricing-faq-wrapper">
                        <AccordianList items={items} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faqs;
