import React, { useState } from 'react';

const AccordianList = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="pricing-faq-accordion">
            {props.items.map((item, index) => (
                <div
                    className={`accordion ${
                        index === activeIndex ? 'active' : ''
                    }`}
                    key={index}
                >
                    <div
                        className="accordion-head"
                        onClick={() => handleClick(index)}
                    >
                        <h3 className='text-xl'>{item.title}</h3>
                        <img
                            src="./assets/icons/chevron-down.svg"
                            alt="Resultid"
                        />
                    </div>
                    <div className="accordion-body">
                        <p className='text-gray-300 font-size text-base'>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AccordianList;
