export const Button = (props) => {
    return (
        <button
            {...props}
            className={`
          text-sm
          rounded
          font-medium
          py-1
          px-3
          text-white
          ${props.className}
        `}
        >
            {props.children}
        </button>
    );
};
