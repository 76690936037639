const defaultAwsConfig = {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: 'http://localhost:3000',
        redirectSignOut: 'http://localhost:3000',
        responseType: 'code',
    },
};

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

export const awsConfig = {
    Auth: {
        ...defaultAwsConfig,
        oauth: {
            ...defaultAwsConfig.oauth,
            redirectSignIn: isLocalhost
                ? 'http://localhost:3000'
                : process.env.REACT_APP_CLIENT_URL,
            redirectSignOut: isLocalhost
                ? 'http://localhost:3000'
                : process.env.REACT_APP_CLIENT_URL,
        },
    },
};

export const awsCredential = {
    email: process.env.REACT_APP_COGNITO_EMAIL,
    password: process.env.REACT_APP_COGNITO_PASSWORD,
};
