import React, { useState } from 'react';

const Dropdown = ({ title, options, links }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="relative"
        >
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-sm font-bold text-white mb-1"
            >
                {title}
            </button>
            {isOpen && (
                <ul className="list-none z-10 bg-white divide-y divide-white rounded shadow-lg overflow-hidden text-sm font-bold absolute w-44 text-black">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="w-44 p-2 hover:bg-gray-200 cursor-pointer"
                        >
                            <a href={links[index]}>{option}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
