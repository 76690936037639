import $ from 'jquery';

$(document).ready(function () {
    $(window).resize(function () {
        $('.header-menu-wrapper').removeClass('open');
        $('body').removeClass('fixed');
    });

    // Menu
    $('.menu-mobile').on('click', function () {
        console.log('this works!');
        $(this)
            .parent('.header-menu')
            .find('.header-menu-wrapper')
            .addClass('open');
        $('body').addClass('fixed');
    });
    $('.close-mobile').on('click', function () {
        $(this).parent('.header-menu-wrapper').removeClass('open');
        $('body').removeClass('fixed');
    });

    // Tab
    $('.tab-nav').on('click', function () {
        var navTarget = $(this).data('nav');
        $(this).addClass('active').siblings().removeClass('active');
        $('body')
            .find('.tab-panel[data-target="' + navTarget + '"]')
            .addClass('active')
            .siblings()
            .removeClass('active');
    });

    // Accordion
    $('.accordion-head').on('click', function () {
        $(this).parent('.accordion').toggleClass('active');
    });

    // Pricing toggle
    $('.pricing-period-toggle').on('click', function () {
        $(this).toggleClass('annualy');
        $('.pricing-card').find('h2.monthly').toggleClass('hide');
        $('.pricing-card').find('h2.annualy').toggleClass('hide');
    });
    $('.pricing-period-monthly').on('click', function () {
        $(this)
            .closest('.pricing-period')
            .find('.pricing-period-toggle')
            .removeClass('annualy');
        $('.pricing-card').find('h2.monthly').removeClass('hide');
        $('.pricing-card').find('h2.annualy').addClass('hide');
    });
    $('.pricing-period-annualy').on('click', function () {
        $(this)
            .closest('.pricing-period')
            .find('.pricing-period-toggle')
            .addClass('annualy');
        $('.pricing-card').find('h2.monthly').addClass('hide');
        $('.pricing-card').find('h2.annualy').removeClass('hide');
    });

    var prices = [
        {
            token: 20000,
            monthlyPrice: 45,
            annualyPrice: 40,
        },
        {
            token: 50000,
            monthlyPrice: 65,
            annualyPrice: 60,
        },
        {
            token: 80000,
            monthlyPrice: 80,
            annualyPrice: 75,
        },
        {
            token: 100000,
            monthlyPrice: 90,
            annualyPrice: 85,
        },
    ];

    $('.pricing-slider-wrapper input').on('change', function () {
        var rangeVal = $(this).val();
        var tick = prices.reduce(function (acc, obj) {
            return Math.abs(rangeVal - obj.token) <
                Math.abs(rangeVal - acc.token)
                ? obj
                : acc;
        });
        $(this)
            .closest('.pricing-card')
            .find('h2.monthly span')
            .text(tick.monthlyPrice);
        $(this)
            .closest('.pricing-card')
            .find('h2.annualy span')
            .text(tick.annualyPrice);
        $(this)
            .closest('.pricing-card')
            .find('p.white span.bold')
            .text(rangeVal);
    });

    // Slick carousel
    $('.testimonial-carousel').each(function () {
        $(this).slick({
            infinite: true,
            prevArrow: $(this)
                .parents('.pricing-testimonial-wrapper')
                .find('.prev'),
            nextArrow: $(this)
                .parents('.pricing-testimonial-wrapper')
                .find('.next'),
        });
    });

    $('.home-hero-text-form form').on('submit', function (e) {
        console.log('form submittied');
    });
});
