import React, { useState } from 'react';

import './../../assets/css/styles.css';
import NarrativeForm from '../../components/narrativeForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function TechTransfer() {
    return (
        <div className="main-content">
            <section className="section-pricing">
                <div className="section-bg section-bg-pricing">
                    <div className="bg-pricing-image">
                        <img
                            src="./../assets/images/bg-pricing.jpg"
                            alt="Resultid"
                        />
                    </div>
                </div>
                <div className="main-container">
                    <div className="section-title pb-3">
                        <h2>
                            <span className="text-gradient-colorful">
                                Streamline
                            </span>{' '}
                            your Technology Commercialization
                        </h2>
                    </div>
                </div>
                <div className="main-container text-white w-full text-lg">
                    <p className="py-3 text-gray-300">
                        <strong className="text-white">
                            Commercialize more technology.
                        </strong>{' '}
                        Are you tasked with the challenging job of efficiently
                        managing a portfolio of early-stage research while
                        constantly evaluating and prioritizing invention
                        disclosures?
                    </p>
                    <p className="py-3 text-gray-300">
                        <strong className="text-white">
                            What if you could gain insights faster, even in
                            disciplines you may not be an expert in?
                        </strong>{' '}
                        Our AI/NLP platform quickly grasps technical concepts,
                        summarizes the disclosure, and formats it in an
                        easy-to-understand document. Now you can translate
                        complicated science into commercially viable
                        opportunities in a familiar format, even without being
                        an expert in a particular discipline.
                    </p>
                    <NarrativeForm
                        title="Technology Commercialization Report"
                        description="Generate a comprehensive commercialization report for any technology based on key information found in invention disclosures"
                        prompt="invention_disclosure"
                        formInputs={{
                            institution: {
                                value: '',
                                type: 'text',
                                label: 'What is the name of your institution?',
                                placeholder: 'Columbia',
                            },
                            industry: {
                                value: '',
                                type: 'text',
                                label: 'Please describe your industry.',
                                placeholder: 'Pharmaceutical Antibiotics',
                            },
                            technology: {
                                value: '',
                                type: 'text',
                                label: 'What is the name of your technology?',
                                placeholder: 'Advanced Vancomycin Antibiotics',
                            },
                            description: {
                                value: '',
                                type: 'textarea',
                                label: 'Please provide a short description of your technology.',
                                placeholder:
                                    'A platform for creating new vancomycin antibiotics with strong effectiveness against multi-drug resistant bacteria and biofilm populations.',
                            },
                        }}
                        sampleData={{
                            name: 'Invention Disclosure',
                            link: 'https://hubs.ly/Q01BKlsN0',
                            file: 'inventiondisclosure.pdf',
                        }}
                        tutorialData={{
                            name: process.env
                                .REACT_APP_TUTORIAL_DATA_UNIVERSITIES_FILENAME,
                            s3Key: process.env
                                .REACT_APP_TUTORIAL_DATA_UNIVERSITIES_S3_KEY,
                        }}
                    />
                    <div className="grid flex grid-cols-2 my-3">
                        <div className="p-6 rounded">
                            <h1 className="text-3xl font-bold pb-4">
                                Benefits
                            </h1>
                            <ul className="gap-2 space-y-3 text-gray-300">
                                <li className="flex items-center">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Be seen as a trusted advisor bridging
                                        research and commercialization
                                    </span>
                                </li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Effectively process high volumes of
                                        complex information to prioritize the
                                        market opportunities
                                    </span>
                                </li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon
                                        icon={solid('star')}
                                        className="text-lg mr-2 mt-1 text-yellow-100"
                                    />
                                    <span>
                                        Streamline your technology
                                        commercialization portfolio and improve
                                        your project management
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="p-6 bg-black/40 rounded-xl border-2 border-pink-600 shadow-xl">
                            <h1 className="text-3xl font-bold pb-4">
                                Features
                            </h1>
                            <ul className="list-none gap-2 space-y-3 text-gray-300">
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-sm mt-[7px] mr-2 text-teal-400"
                                    />
                                    <span>
                                        AI/NLP technology automatically
                                        summarizes your information for fast and
                                        efficient data analysis
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-sm mt-[7px] mr-2 text-teal-400"
                                    />
                                    <span>
                                        You can quickly generate and share a
                                        comprehensive market analysis report in
                                        minutes
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FontAwesomeIcon
                                        icon={solid('check')}
                                        className="text-sm mt-[7px] mr-2 text-teal-400"
                                    />
                                    <span>
                                        Add your expertise, insights, and
                                        knowledge to produce a robust
                                        commercialization report
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TechTransfer;
