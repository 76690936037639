import axios from 'axios';
import { Auth } from 'aws-amplify';
import { awsCredential } from './config';
// create an AxiosInstance
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession()
                .then((session) => {
                    const idTokenExpiresAt = session
                        .getIdToken()
                        .getExpiration();
                    const accessTokenExpiresAt = session
                        .getIdToken()
                        .getExpiration();
                    let id_token = session.getIdToken().getJwtToken();
                    let accessToken = session.getAccessToken().getJwtToken();
                    const currentTimeSeconds = Math.round(
                        Number(new Date()) / 1000
                    );

                    if (
                        idTokenExpiresAt <= currentTimeSeconds ||
                        accessTokenExpiresAt <= currentTimeSeconds
                    ) {
                        Auth.currentAuthenticatedUser().then((res) => {
                            res.refreshSession(
                                session.getRefreshToken(),
                                (err, data) => {
                                    if (err) {
                                        Auth.signOut();
                                    } else {
                                        id_token = data
                                            .getIdToken()
                                            .getJwtToken();
                                        accessToken = data
                                            .getAccessToken()
                                            .getJwtToken();
                                    }
                                }
                            );
                        });
                    }
                    resolve({
                        ...config,
                        headers: {
                            ...config.headers,
                            id_token,
                            authorization: `Bearer ${accessToken}`,
                        },
                    });
                })
                .catch((reason) => {
                    sessionStorage.clear();
                    Auth.signOut();
                    reject(reason);
                });
        });
    },
    (error) => Promise.reject(error)
);

export const getPresignedUrl = (file, email) => {
    return api.post('/landing_presigned_url', {
        data: {
            filename: file.name,
            email: awsCredential.email,
        },
    });
};

export const uploadFileToS3 = (file, presignedUrl) => {
    return axios.put(presignedUrl, file, {
        headers: {
            'Content-Type': 'text/plain',
        },
        onUploadProgress: (progressEvent) => {
            console.log(
                'Upload Progress: ' +
                    Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    ) +
                    '%'
            );
        },
    });
};

export async function submitForm(useCase, formData, s3Key, originalFileName) {
    // create an object to store the data
    const data = {
        user_email: formData.find((input) => input.id === 'email').value,
        file_s3_key: s3Key,
        original_file_name: originalFileName,
        usecase_name: useCase,
        json_var: [],
    };

    // Iterate over the formData add them to the json_var
    const filteredInputs = formData.filter(
        (input) => input.id !== 'email' && input.id !== ''
    );

    filteredInputs.forEach((input) => {
        data.json_var.push({
            variable_name: input.id,
            user_input: input.value,
        });
    });

    console.log('data', data);
    // Send the request using the api object and return the promise.
    return api.post('/landing_page', { data });
}

export const downloadSampleData = (fileName) => {
    // using Java Script method to get PDF file
    fetch(`./sample_data/${fileName}`).then((response) => {
        response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });
};

const hubspotAPI = axios.create({
    baseURL: 'https://api.hubapi.com',
    headers: {
        'Content-Type': 'application/json',
    },
});

export const sendEmailToHubspot = (email) => {
    return hubspotAPI.post(
        '/contacts/v1/contact/',
        {
            properties: [
                {
                    property: 'email',
                    value: email,
                },
            ],
        },
        {
            params: {
                hapikey: 'pat-na1-2a7b509f-6183-4d5d-be03-03b214e79615',
            },
        }
    );
};

// export both api and submitForm
export default api;
